import { createFormActions, createInput, createInputFile, createSelect, createTextArea } from ":shared/components/form";
import { file } from ":shared/helpers/validations";

export function createProjectSubmissionInputs(props: { image_max: string | number; pdf_max: string | number }) {
  const pdf_max = typeof props.pdf_max === "string" ? parseInt(props.pdf_max) : props.pdf_max ?? 5;
  const image_max = typeof props.image_max === "string" ? parseInt(props.image_max) : props.image_max ?? 5;
  const TeamName = createInput("", {
    // required: true,
  });
  const Track = createInput("", {
    // required: false,
  });
  const ProjectName = createInput("", {
    // required: true,
  });
  const ProjectCover = createInputFile(null, {
    // required: false,
    validators: (v) => {
      if (!v) return undefined;
      else if (typeof v === "string") return undefined;
      const uploaded_file = v[0];
      const is_safe_size = file.isFileSizeLessThan(uploaded_file, image_max, "MB");
      return !is_safe_size ? undefined : { [is_safe_size]: true };
    },
  });
  const ProblemStatement = createTextArea("", {
    // required: false,
  });
  const ResearchProcess = createTextArea("", {
    // required: false,
  });
  const Ideation = createTextArea("", {
    // required: false,
  });
  const ApplicableSolution = createTextArea("", {
    // required: false,
  });

  const Feasibility = createTextArea("", {
    // required: false,
  });
  const BusinessModel = createTextArea("", {
    // required: false,
  });

  const Impact = createTextArea("", {
    // required: false,
  });

  const ProjectVideo = createInput("", {
    // required: false,
  });

  const ProjectImages = createInputFile(undefined, {
    // required: false,
    validators: (v) => {
      if (!v) return undefined;
      else if (typeof v === "string") return undefined;
      const uploaded_file = v[0];
      const is_safe_size = file.isFileSizeLessThan(uploaded_file, pdf_max, "MB");
      return !is_safe_size ? undefined : { [is_safe_size]: true };
    },
  });

  const Presentation = createInputFile(undefined, {
    // required: false,
    validators: (v) => {
      if (!v) return undefined;
      else if (typeof v === "string") return undefined;
      const uploaded_file = v[0];
      const is_safe_size = file.isFileSizeLessThan(uploaded_file, pdf_max, "MB");
      return !is_safe_size ? undefined : { [is_safe_size]: true };
    },
  });

  const Actions = createFormActions(
    {
      TeamName,
      Track,
      ProjectName,
      ProjectCover,
      ProblemStatement,
      ResearchProcess,
      Ideation,
      ApplicableSolution,
      Feasibility,
      BusinessModel,
      Impact,
      ProjectVideo,
      ProjectImages,
      Presentation,
    },
    {
      defaultCase: "snake",
    }
  );
  return {
    $$META: {
      pdf_max,
      image_max,
    },
    Actions,
    TeamName,
    Track,
    ProjectName,
    ProjectCover,
    ProblemStatement,
    ResearchProcess,
    Ideation,
    ApplicableSolution,
    Feasibility,
    BusinessModel,
    Impact,
    ProjectVideo,
    ProjectImages,
    Presentation,
  };
}
